<template>
  <div>
    <ul class="list_bg_bullt">
      <li>{{ $poData.leave_service_note_1 }}</li>
      <li>{{ $poData.leave_service_note_2 }}</li>
      <li>탈퇴 전 저금통을 정리해주세요. 저금통 상세화면에서 끝내기나 중도 포기(톱니바퀴)를 눌러주세요</li>
      <li>{{ $poData.leave_service_note_3 }}</li>
      <li>인앱 결제는 자동으로 취소되지 않아요. 멤버십 관리 메뉴에서 <em class="tc_red">정기결제 구독 취소 후</em> 탈퇴해 주세요</li>
      <li>{{ $poData.leave_service_note_5_1 }}<em class="tc_red">{{ $poData.leave_service_note_5_2 }}</em>{{ $poData.leave_service_note_5_3 }}</li>
    </ul>
    <div class="area_left_money">
      <div class="head_left_money">
        <dl class="info_left_money">
          <dt>{{ $poData.leave_service_form_heading_1 }}</dt>
          <dd :class="{ dd_empty : viewModel.model.wallet.money_amount === 0 }">{{ viewModel.model.wallet.money_amount | convertNumberToComma(false,'0원','원') }}
            <button
              class="btn_guide"
              @click="$emit('onClickBalanceHelpersheetPopup')">
              <IconSvg iconName="guide" size="24" iconColor="#BFBFBF"/>
            </button>
          </dd>
        </dl>
      </div>
      <div v-if="viewModel.model.wallet.money_amount > 0" class="body_left_money">
        <Selectbox
          :title="$poData.leave_service_form_placeholder_1_1"
          :placeholder="$poData.leave_service_form_placeholder_1_1"
          thumnailKey="logo_image_url"
          :isCloseBtn="true"
          :dataList="viewModel.model.bank_company_list | setDataList('uid','name')"
          :value.sync="viewModel.model.bank_company_uid"
          @update:value="viewModel.onCheckBankAccountAuth()" />
        <Input
          :placeholder="$poData.leave_service_form_placeholder_1_2"
          :value.sync="viewModel.model.bank_account_number"
          valueType="accountNum"
          type="number"
          inputmode="numeric"
          :maxLength="16"
          :isBlockUnValueType="true"
          @onBlur="viewModel.onCheckBankAccountAuth()"
          @onEnterKey="viewModel.onCheckBankAccountAuth()"/>
        <p v-if="!viewModel.model.is_bank_account_auth" class="desc_left_money">{{ $poData.leave_service_form_supporting_1_1 }}</p>
        <p v-else class="desc_left_money">{{ $poData.leave_service_form_supporting_1_2.format(viewModel.model.user.name) }}</p>
      </div>
      <div v-if="viewModel.model.user.role === 'PARENTS'" class="foot_left_money">
        <CheckboxOnlyOne
          id="is_agree_family_delete"
          :value.sync="viewModel.model.is_agree_family_delete"
          :text="$poData.leave_service_leave_group_helper">
        </CheckboxOnlyOne>
        <button
          class="btn_guide"
          @click="$emit('onClickParentHelpersheetPopup')">
          <IconSvg iconName="guide" size="24" iconColor="#BFBFBF"/>
        </button>
      </div>
    </div>
   <div class="area_fixed_bottom">
      <Button
        btnStyle="primary"
        :text="$poData.leave_service_btn"
        :disabled="isVaild"
        @onClickBtn="$emit('onClickCompleteLeave')" />
      <p v-if="viewModel.model.user.is_under_fourteen" class="desc_foot">{{ $poData.leave_service_btn_supporting_1_1 }}<em class="tc_red">{{ $poData.leave_service_btn_supporting_1_2 }}</em>{{ $poData.leave_service_btn_supporting_1_3 }}</p>
   </div>
  </div>
</template>

<script>
import Selectbox from '@/components/common/selectbox/Selectbox';
import Input from '@/components/common/input/Input';
import CheckboxOnlyOne from '@/components/common/checkbox/CheckboxOnlyOne';
import IconSvg from '@/components/common/icon/IconSvg';
import Button from '@/components/common/button/Button';

export default {
  name: 'LeaveService',
  components:{
    Selectbox,
    Input,
    CheckboxOnlyOne,
    IconSvg,
    Button,
  },
  props:{
    viewModel: Object,
  },
  computed:{
    isVaild(){
      return this.viewModel.validatedResult()
    }
  }
}
</script>

<style scoped>
.list_bg_bullt{padding:24px 20px;background-color:#F7F6FA}
.list_bg_bullt li{padding-left:15px;font-size:14px;line-height:22px;text-indent:-15px;color:#333}
.list_bg_bullt li:before{display:inline-block;width:3px;height:3px;margin:10px 6px 9px;border-radius:100%;background-color:#333;vertical-align:top;content:''}
.list_bg_bullt li + li{margin-top:14px}

.btn_guide{display:inline-block;margin-left:2px;vertical-align:top}

.area_left_money{padding:32px 20px 44px}

.area_left_money .info_left_money{overflow:hidden}
.area_left_money .info_left_money dt,
.area_left_money .info_left_money dd{float:left;width:50%;font-weight:600;font-size:14px;line-height:24px}
.area_left_money .info_left_money dt{color:#666}
.area_left_money .info_left_money dd{text-align:right;color:#000}
.area_left_money .info_left_money dd.dd_empty{color:#999}

.area_left_money .selectbox_comm{display:block;margin-top:4px}
.area_left_money .selectbox_comm + .tf_comm{margin-top:4px}

.area_left_money .desc_left_money{margin-top:6px;font-size:14px;line-height:22px}

.area_left_money .head_left_money + .foot_left_money{margin-top:17px;padding-top:24px;border-top:1px solid #E7E5E5}
.area_left_money .body_left_money + .foot_left_money{margin-top:32px}

.area_fixed_bottom{position:absolute;bottom:0;left:0;right:0;padding:0 20px 32px}
.desc_foot{padding:32px 0 4px;text-align:center}
</style>
<template>
  <div class="check_comm">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'CheckboxBase'
}
</script>

<style scoped>
.check_comm{display:inline-block;position:relative;min-width:22px;min-height:24px;vertical-align:top}
.check_comm ::v-deep input{position:absolute;top:0;bottom:0;left:0;right:0;z-index:10;width:100%;height:100%;border:0 none;opacity:0.01;filter:alpha(opacity=1);cursor:pointer}
.check_comm ::v-deep label{display:block;padding-left:36px;line-height:24px}
.check_comm ::v-deep .txt_checkbox{display:inline-block;font-weight:700;font-size:16px;line-height:24px;color:#111;letter-spacing:-0.02em;word-break:break-all;vertical-align:top}
.check_comm ::v-deep .ir_pm{padding-left:0}
.check_comm ::v-deep .icon_check{position:absolute;top:-2px;bottom:0;left:0;width:24px;height:24px;z-index:1;margin:auto;border:1px solid #E7E5E5;border-radius:4px;font-size:0;background-color:#fff;white-space:nowrap;box-sizing:border-box;text-align:center}
.check_comm ::v-deep .icon_check g{fill:#A6A4A4}
.check_comm ::v-deep .icon_check:after{display:inline-block;height:100%;vertical-align:middle;content:''}
.check_comm ::v-deep .icon_check svg{display:inline-block;vertical-align:middle;white-space:normal}

/* hover */
/* .check_comm ::v-deep input:hover + label .txt_checkbox{color:$PRIMARY_COLOR} */

/* cheked */
.check_comm ::v-deep input:checked + label + .icon_check{border-color:#BEA9ED;background-color:#BEA9ED}
.check_comm ::v-deep input:checked + label + .icon_check g{fill:#232323}

/* disabled */
/* .check_comm ::v-deep input:disabled,
.check_comm ::v-deep input:disabled:checked{cursor:no-drop}
.check_comm ::v-deep input:disabled + label .txt_checkbox{color:$DISABLED_TEXT}
.check_comm ::v-deep input:disabled + label + .icon_check{border-color:$DISABLED_BORDER;background-color:$DISABLED_BG}
  .check_comm ::v-deep input:disabled + label + .icon_check g{fill:$DISABLED_BG}
.check_comm ::v-deep input:disabled:checked + label + .icon_check g{fill:$DISABLED_ICON} */

/* .check_comm + .check_comm{margin-left:32px} */

.check_comm_large{display:block;height:50px;min-height:inherit}
.check_comm_large ::v-deep label{padding:13px 10px 11px 48px;border:1px solid #e1e1e1;border-radius:4px;font-weight:500;font-family:'NotoSansKR_M', sans-serif;font-size:14px;line-height:24px;color:#555}
.check_comm_large ::v-deep .icon_check{left:14px;margin-top:12px}
.check_comm_large ::v-deep input:checked + label{border:1px solid #555;color:#222}
</style>
<template>
  <CheckboxBase>
    <input type="checkbox" 
      :id="id" 
      :value="id" 
      v-model="input" 
      :disabled="isDisabled">
    <label :class="{ir_pm : text == undefined}" :for="id">
      <span class="txt_checkbox"><slot/>{{ text }}</span>
    </label>
    <IconSvg iconName="check" size="18" iconColor="#A6A4A4"/>
  </CheckboxBase>
</template>

<script>
import CheckboxBase from './CheckboxBase'
import IconSvg from '@/components/common/icon/IconSvg';

export default {
  name: 'CheckboxOnlyOne',
  components: {
    CheckboxBase,
    IconSvg
  },
  props: {
    id: String,
    value: Boolean,
    text: String,
    isDisabled: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    input: {
      get() {
        return this.value ? [ this.id ] : [];
      },
      set(val) {
        const result = val.length > 0;
        this.$emit("update:value", result);
      }
    }
  }
}
</script>

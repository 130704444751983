import { getUserAgent } from "../utils/device";
export var PageMoveParam;
(function (PageMoveParam) {
    PageMoveParam["savingsCreate"] = "savingsCreate";
    PageMoveParam["bankAccounts"] = "bankAccounts";
})(PageMoveParam || (PageMoveParam = {}));
export function webEnd() {
    var userAgent = getUserAgent();
    //안드로이드
    if (userAgent === 'ANDROID') {
        window.FirFinInterface.webEnd();
    }
    //IOS
    else if (userAgent === 'IOS') {
        window.webkit.messageHandlers.FirFinInterface.postMessage('webEnd');
    }
}

import { render, staticRenderFns } from "./LeaveService.vue?vue&type=template&id=c6ed7550&scoped=true&"
import script from "./LeaveService.vue?vue&type=script&lang=js&"
export * from "./LeaveService.vue?vue&type=script&lang=js&"
import style0 from "./LeaveService.vue?vue&type=style&index=0&id=c6ed7550&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6ed7550",
  null
  
)

export default component.exports
<template>
  <PageWithLayout
    :class="viewModel.page.class"
    :isBodyFull="true"
    :isBackBtn="true"
    :isBackWebEnd="viewModel.page.isBackWebEnd"
    :isTitle="true"
    :title="viewModel.page.title"
    :isRightTextMenu="viewModel.page.isRightTextMenu"
    :rightTextMenu="viewModel.page.rightTextMenu"
    :bottomPadding="viewModel.page.component === 'LeaveService' && viewModel.model.user.is_under_fourteen ? 145 : 104"
    @onClickBack="viewModel.page.onClickBack()"
    @onClickRightMenu="viewModel.page.onClickRightMenu()">
      <component
        :is="viewModel.page.component"
        :viewModel="viewModel"
        @onClickBalanceHelpersheetPopup="viewModel.onClickBalanceHelpersheetPopup()"
        @onClickParentHelpersheetPopup="viewModel.onClickParentHelpersheetPopup()"
        @onClickCompleteLeave="viewModel.onClickCompleteLeave()" />
      <template v-slot:popup>
        <Layer
          :title="$poData.leave_service_balance_helpersheet_title"
          v-if="viewModel.isBalanceHelpersheetPopup"
          @onClickClose="viewModel.onClickBalanceHelpersheetPopupClose()">
          <p class="desc_drop" v-html="$poData.leave_service_balance_helpersheet_desc"></p>
          <dl class="list_drop">
            <dt>{{ $poData.leave_service_balance_helpersheet_money }}</dt>
            <dd>{{ viewModel.model.wallet.money_amount | convertNumberToComma(false,'0원','원') }}</dd>
          </dl>
          <dl class="list_drop">
            <dt>{{ $poData.leave_service_balance_helpersheet_point }}</dt>
            <dd>{{ viewModel.model.wallet.point_amount | convertNumberToComma(false,'0원','원') }}</dd>
          </dl>
        </Layer>
        <Layer
          :title="!viewModel.model.family.has_partner ? $poData.leave_service_one_parent_helpersheet_title : $poData.leave_service_two_parents_helpersheet_title"
          v-if="viewModel.isParentHelpersheetPopup"
          @onClickClose="viewModel.onClickParentHelpersheetPopupClose()">
          <!-- 1부모 -->
          <template v-if="!viewModel.model.family.has_partner">
            <strong class="tit_drop">{{ $poData.leave_service_one_parent_helpersheet_heading_1 }}</strong>
            <p class="desc_drop">{{ $poData.leave_service_one_parent_helpersheet_desc_1 }}</p>
            <strong class="tit_drop">{{ $poData.leave_service_one_parent_helpersheet_heading_2 }}</strong>
            <p class="desc_drop">{{ $poData.leave_service_one_parent_helpersheet_desc_2 }}</p>
          </template>
          <!-- 2부모 -->
          <template v-else>
            <strong class="tit_drop">{{ $poData.leave_service_two_parents_helpersheet_heading_1 }}</strong>
            <p class="desc_drop">{{ $poData.leave_service_two_parents_helpersheet_desc_1 }}</p>
            <strong class="tit_drop">{{ $poData.leave_service_two_parents_helpersheet_heading_2 }}</strong>
            <p class="desc_drop">{{ $poData.leave_service_two_parents_helpersheet_desc_2 }}</p>
          </template>
        </Layer>
      </template>
  </PageWithLayout>
</template>
<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
import LeaveServiceReason from '@/views/leave/view/components/LeaveServiceReason';
import LeaveService from '@/views/leave/view/components/LeaveService';
import Layer from '@/components/layout/popup/Layer';
// mixins
import InterFaceMixin from '@/mixins/InterFaceMixin';
import BackKeyEventMixin from '@/mixins/BackKeyEventMixin';
// viewModel
import LeaveViewModel from '@/views/leave/viewModel/LeaveViewModel'

export default {
  name: 'LeaveServiceLayout',
  mixins: [ InterFaceMixin, BackKeyEventMixin ],
  components:{
    PageWithLayout,
    LeaveServiceReason,
    LeaveService,
    Layer,
  },
  beforeMount(){
    this.viewModel.init();
  },
  data(){
    return{
      viewModel: new LeaveViewModel(),
    }
  },
  methods:{
    onClickBackKey(){
      if(this.viewModel.isBalanceHelpersheetPopup){
        this.viewModel.onClickBalanceHelpersheetPopupClose()
      }
      else if(this.viewModel.isParentHelpersheetPopup){
        this.viewModel.onClickParentHelpersheetPopupClose()
      }
      else if(this.viewModel.page.isBackWebEnd){
        this.webEnd();
      }
      else{
        this.viewModel.page.onClickBack()
      }
    }
  }
}
</script>

<style scoped>
.inner_app_leave_service_reason >>> .cont_firfin{position:relative;padding-top:16px}
.inner_app_leave_service >>> .cont_firfin{position:relative}

.wrap_firfin .textarea_comm{margin:16px 20px 24px}
.wrap_firfin .btn_primary{position:absolute;bottom:32px;left:20px;width:calc(100% - 40px)}
</style>
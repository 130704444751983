<template>
  <div
    id="selectboxComm"
    :class="[
      'selectbox_comm',
      {
        active: isActive,
        disabled: isDisabled,
        selectbox_error: isError,
        valued: String(value).length > 0
      },
      size == 'small' ? 'selectbox_small' : ''
    ]" 
    :style="{ maxWidth: maxWidth }"
    ref="selectElement">
    <button class="btn_label" @click="onClickSelectbox">
      <!-- 다중선택 -->
      <template v-if="canMultiple">
        <!-- 다중선택 선택후 -->
        <ul
          v-if="String(value).length > 0"
          class="list_tag">
          <li
            v-for="item in value"
            :key="`tag_${item}`">
            <button class="btn_tag" @click.stop="onClickTag(item)">{{ valueText(item) }}<IconSvg iconName="remove" size="14" iconColor="#aaa"/></button>
          </li>
        </ul>
        <!-- 다중선택 선택전 -->
        <template v-else>
          {{ placeholder }}
        </template>
      </template>
      <!-- 단일선택 -->
      <template v-if="!canMultiple">
        <div v-if="thumnailKey" class="img_option" :style="{ 'background-image' : `url(${thumbnail})`}"></div>
        <span class="txt_option">{{ valueText(this.value) || placeholder }}</span>
      </template>
      <IconSvg
        iconName="arrow_fold"
        size="24"
        :iconColor="size == 'small' ? '#232323' : '#000'"/>
    </button>
  </div>
</template>

<script>
import IconSvg from '@/components/common/icon/IconSvg';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Selectbox',
  components: {
    IconSvg
  },
  props: {
    size: {
      type:String,
      default:'default' // default, small
    },
    dataList: Array,
    value: [String, Number, Array],
    placeholder: {
      type: String,
      default: '선택'
    },
    maxWidth: String,
    showLine:{
      type: Number,
      default: 5
    },
    canMultiple:{
      type:Boolean,
      default:false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    thumnailKey: String,
    title: String,
    isCloseBtn: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters('commonDropBox', [ 'getDropBoxUid', 'getIsSelectDropBox', 'getDropBoxTargetHeight', 'getDropBoxSeletData']),
    isActive(){
      return this.getDropBoxUid == this._uid && this.getIsSelectDropBox
    },
    thumbnail(){
      if(this.thumnailKey && this.value && this.dataList.find(item => item.id === this.value))
      return this.dataList.find(item => item.id === this.value)[this.thumnailKey]
    }
  },
  watch:{
    getDropBoxSeletData(){
      if(!this.getDropBoxSeletData || Object.keys(this.getDropBoxSeletData).length === 0 || this.getDropBoxUid != this._uid) return;
      this.setValue(this.getDropBoxSeletData)
    },
  },
  updated(){
    if(this.getDropBoxTargetHeight != this.$refs.selectElement.offsetHeight){
      this.fetchDropBoxTargetHeight(this.$refs.selectElement.offsetHeight)
    }
  },
  methods:{
    ...mapActions('commonDropBox', [ 'fetchDropBoxStart', 'fetchDropBoxTargetHeight', 'fetchDropBoxSelect', 'fetchDropBoxSelect', 'fetchDropBoxValue', 'fetchDropBoxEnd' ]),
    onClickSelectbox(e){
      if(!this.isDisabled && (!this.getIsSelectDropBox || this.getDropBoxUid != this._uid)){
        // this.fetchDropBoxValue(this.value)
        this.fetchDropBoxStart({
          uid: this._uid,
          dropType: 'select',
          dataList: this.dataList,
          target: this.$refs.selectElement,
          value: this.value,
          option: {
            showLine : this.showLine,
            canMultiple: this.canMultiple,
            thumnailKey: this.thumnailKey,
            title: this.title,
            isCloseBtn : this.isCloseBtn
          }
        });
        // window.addEventListener('click',this.onBlur,true)
      }else if(this.getIsSelectDropBox && this.getDropBoxUid === this._uid){
        this.closeDropBox()
      }
    },
    valueText(value){
      const selectedId = this.dataList.find(item => item.id === value);
      return selectedId ? selectedId.text : '';
    },
    setValue(option){
      if(!this.canMultiple){
        this.$emit("update:value", option.id);
        this.fetchDropBoxValue(option.id)
        this.closeDropBox()
      }else{
        const isSelectedValue = this.value.indexOf(option.id) > -1;
        let newValue = [];
        if(!isSelectedValue){
          newValue = newValue.concat(this.value);
          newValue.push(option.id);
          this.$emit("update:value", newValue);
          this.fetchDropBoxValue(newValue)
        }else{
          newValue = this.value.filter(item => item != option.id);
          this.$emit("update:value", newValue);
          this.fetchDropBoxValue(newValue)
        }
      }
      this.fetchDropBoxSelect({})
    },
    onClickTag(value){
      let newValue = [];
      newValue = newValue.concat(this.value);
      newValue = newValue.filter(item => item != value);
      this.$emit("update:value", newValue);
      this.fetchDropBoxValue(newValue)
    },
    // onBlur(e){
    //   if(e.path.findIndex(item => item.id === 'selectboxComm' || item.id === 'boxDrop') < 0){
    //     window.removeEventListener('click',this.onBlur,true);
    //     this.closeDropBox()
    //   }
    // },
    closeDropBox(){
      this.fetchDropBoxEnd()
    }
  }
}
</script>

<style scoped>
.selectbox_comm{display:inline-block;position:relative;vertical-align:top}
.selectbox_comm .btn_label{display:inline-block;overflow:hidden;position:relative;width:100%;min-height:54px;padding:15px 56px 15px 16px;outline:none;border:0 none;border-radius:4px;font-weight:600;font-size:16px;line-height:24px;background-color:#F3F1F1;color:#999;box-sizing:border-box;vertical-align:top;text-align:left;letter-spacing:-.02em;white-space:nowrap;text-overflow:ellipsis}
/* .selectbox_comm .btn_label .img_option{float:left;width:24px;height:24px;margin:6px 8px 6px 0;border-radius:4px;background-color:$DISABLED_BG;background-repeat:no-repeat;background-size:cover;background-position:center;vertical-align:top}
.selectbox_comm .btn_label .txt_option{display:block;padding:3px 0;line-height:30px} */
.selectbox_comm .btn_label .txt_option{margin-right:16px}
.selectbox_comm .btn_label .icon_arrow_fold{position:absolute;top:50%;right:16px;margin-top:-12px}

/* small */
.selectbox_small .btn_label{min-height:36px;padding:7px 30px 7px 16px;border: 1px solid #BEA9ED;border-radius:100px;font-weight:500;font-size:14px;line-height:22px;background-color:#F0EAFA}
.selectbox_small .btn_label .txt_option{margin-right:0}
.selectbox_comm .btn_label .icon_arrow_fold{right:6px}

/* multi tag
.list_tag{margin-left:-10px;padding:2px 0;white-space:normal}
.list_tag li{display:inline-block;vertical-align:top}
.list_tag li .btn_tag{margin:3px;padding:0 10px;border-radius:14px;border:1px solid $DISABLED_BORDER;line-height:24px;background-color:$DISABLED_BG;color:$ACTIVE_TEXT}
.list_tag li .btn_tag .icon_remove{margin:5px 0 0 6px;} */

/* // active
.selectbox_comm.active .btn_label{border:1px solid $PRIMARY_COLOR}
.selectbox_comm.active .icon_arrow_bottom{transform:scaleY(-1)} */

/* valued */
.selectbox_comm.valued .btn_label{color:#111}

/* // disabled
.selectbox_comm.disabled .btn_label{border-color:#f0f0f0;background-color:$DISABLED_BG;color:$DISABLED_TEXT;cursor:no-drop}
.selectbox_comm.disabled .btn_label .icon_arrow_bottom ::v-deep g{fill:$DISABLED_ICON} */

</style>

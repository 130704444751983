<template>
  <div>
    <Radio
      :dataList="viewModel.model.unregister_type_list | setDataList('value','key')"
      :value.sync="viewModel.model.unregister_type"
      @update:value="value => onUpdateUnregisterType(value)" />
    <Textarea
      :placeholder="$poData.leave_service_reason_form_placeholder"
      :value.sync="viewModel.model.unregister_description"
      :isFocus="isFocusDescription"/>
    <Button
      btnStyle="primary"
      :text="$poData.leave_service_reason_btn"
      :disabled="!isVaild"
      @onClickBtn="viewModel.onGoNextPage()" />
  </div>
</template>

<script>
import Radio from '@/components/common/radio/Radio';
import Textarea from '@/components/common/textarea/Textarea';
import Button from '@/components/common/button/Button';

export default {
  name: 'LeaveServiceReason',
  components:{
    Radio,
    Textarea,
    Button,
  },
  props:{
    viewModel: Object
  },
  data(){
    return{
      isFocusDescription: false
    }
  },
  computed:{
    isVaild(){
      const type = this.viewModel.model.unregister_type;
      const isEtc = type === 'ETC';
      const isDesc = this.viewModel.model.unregister_description.length > 0;
      return type && ( !isEtc || isEtc && isDesc )
    }
  },
  methods:{
    onUpdateUnregisterType(value){
      if(value === 'ETC'){
        this.isFocusDescription = true;
      }else{
        this.isFocusDescription = false;
      }
    }
  }
}
</script>

<style scoped>
.inner_app >>> .cont_firfin{position:relative;padding:16px 0 104px}

.wrap_firfin .textarea_comm{margin:16px 20px 24px}
.wrap_firfin .btn_primary{position:absolute;bottom:32px;left:20px;width:calc(100% - 40px)}
</style>
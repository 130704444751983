export function getUserAgent() {
    var userAgent = navigator.userAgent.toLowerCase() || '';
    if (userAgent.indexOf('android') > -1) {
        return 'ANDROID';
    }
    else if (userAgent.indexOf('iphone') > -1 ||
        userAgent.indexOf('ipad') > -1 ||
        userAgent.indexOf('ipod') > -1 ||
        userAgent.indexOf('mac') > -1) {
        return 'IOS';
    }
    else {
        return 'ETC';
    }
}

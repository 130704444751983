<template>
  <div class="radio_comm">
    <input 
      type="radio"
      :name="name"
      :disabled="isDisabled" 
      :id="id" 
      :value="id"
      v-model="input">
      <!-- :checked="checked" -->
    <label :for="id">
      <!--  :style="`background-color:${ id | convertIdToColor(colorSelect) }`" -->
      <span class="txt_radio"
        ><span
          v-if="colorSelect"
          :style="`background-color:${ $options.filters.convertIdToColor(id, colorSelect) }`"
          class="box_color"></span
        ><IconSvg
          v-if="item.icon"
          :iconName="item.icon.iconName"
          :size="14"
          :iconColor="item.icon.iconColor"
          :iconDarkColor="item.icon.iconDarkColor"
          :bgcolor="selected ? item.icon.bgcolor : item.icon.bgcolor +'99'"
          />{{ text }}</span>
    </label>
    <span class="icon_radio">
      <IconSvg
        iconName="check"
        :size="18"
        :iconColor="selected ? '#232323' : '#A6A4A4'" />
      <!-- <span class="mark_active"></span> -->
    </span>
  </div>
</template>

<script>
import IconSvg from '@/components/common/icon/IconSvg';

export default {
  name: 'RadioItem',
  components:{
    IconSvg
  },
  props: {
    item:Object,
    id: undefined,
    text: String,
    name: String,
    value: undefined,
    colorSelect: String,
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selected() {
      return this.id === this.value
    },
    checked() {
      return this.selected ? 'checked' : null;
    },
    input: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      }
    },
  },
}
</script>
import LeaveModel from '@/views/leave/model/LeaveModel';
import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import store from '@/store/index';
import { pageValidated } from '@/utils/validateUtils'
import Vue from 'vue'
import poData from '@/constants/poData.js';
import { webEnd } from '@/helpers/firfinInterface';

export default class LeaveViewModel {
  constructor() {
    this.model = new LeaveModel();
    this.page = {};
    this.pageDataList = [
      {
        index:0,
        component: 'LeaveServiceReason',
        class: 'inner_app_leave_service_reason',
        isBackWebEnd: true,
        title: poData.leave_service_reason_title,
        isRightTextMenu: true,
        rightTextMenu: poData.leave_service_reason_skip,
        onClickBack : () => { return },
        onClickRightMenu : () => { this.onGoNextPage() }
      },
      {
        index:1,
        component: 'LeaveService',
        class : 'inner_app_leave_service',
        isBackWebEnd: false,
        title: poData.leave_service_title,
        isRightTextMenu: false,
        rightTextMenu: '',
        onClickBack : () => { this.onGoPrevPage() },
        onClickRightMenu : () => { return },
      }
    ];
    this.vaildData = {};
    this.isBalanceHelpersheetPopup = false; // 잔액 도움말 시트
    this.isParentHelpersheetPopup = false; // 부모 그룹정리 도움말 시트
  }
  init(){
    this.page = this.pageDataList[0];
    this.getLeaveServiceView();
    this.getBankCompanyAllList();
  }
  setVaildData(){
    if(this.model.wallet.money_amount > 0){
      this.vaildData.bank_company_uid = {
        value:'bank_company_uid'
      }
      this.vaildData.bank_account_number = {
        value:'bank_account_number'
      }
      this.vaildData.is_bank_account_auth = {
        value:'is_bank_account_auth',
        valueType:'boolean_true',
      }
    }
    if(this.model.user.role === 'PARENTS'){
      this.vaildData.is_agree_family_delete = {
        value:'is_agree_family_delete',
        valueType:'boolean_true',
      }
    }
  }
  validatedResult(){
    return pageValidated(this.vaildData, this.model)
  }
  onGoPrevPage(){
    const prevPageIndex = this.page.index - 1;
    if(prevPageIndex < 0) return;
    this.page = this.pageDataList[prevPageIndex];
  }
  onGoNextPage(){
    const nextPageIndex = this.page.index + 1;
    if(nextPageIndex > this.pageDataList.length -1) return;
    this.page = this.pageDataList[nextPageIndex];
  }

  onCheckBankAccountAuth(){
    this.model.is_bank_account_auth = false;
    if(this.model.bank_company_uid && this.model.bank_account_number){
      this.postBankAccountAuth();
    }
  }

  onClickBalanceHelpersheetPopup(){
    this.isBalanceHelpersheetPopup = true;
  }
  onClickBalanceHelpersheetPopupClose(){
    this.isBalanceHelpersheetPopup = false;
  }

  onClickParentHelpersheetPopup(){
    this.isParentHelpersheetPopup = true;
  }
  onClickParentHelpersheetPopupClose(){
    this.isParentHelpersheetPopup = false;
  }
  
  onClickCompleteLeave(){
    const requestLeave = () => {
      const leaveData = this.model.getLeaveData();
      //안드로이드
      if ( Vue.prototype.$varUA === 'ANDROID') {
        window.FirFinInterface.leaveService(JSON.stringify(leaveData));
      }
      //IOS
      else if ( Vue.prototype.$varUA === 'IOS' ) {
        window.webkit.messageHandlers.FirFinInterface.postMessage(
          {
            name: "leaveService",
            leaveData: JSON.stringify(leaveData)
          }
        );
      }
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `<strong>${poData.leave_service_reconfirm_title}</strong>${poData.leave_service_reconfirm_desc}`,
      closeBtnText: poData.leave_service_reconfirm_cancel,
      compeleteBtnText: poData.leave_service_reconfirm_yes,
      confirmCallBack: requestLeave,
    });
  }

  getLeaveServiceView(){
    const path = `${apiPath.LEAVE_SERVICE_VIEW}`;
    GET_REQUEST(path, {
      closeButtonCallback: webEnd,
    }).then(
    (success) => {
      const resultData = success.data.body;
      this.model.setData(resultData);
      this.setVaildData()
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }

  getBankCompanyAllList(){
    const path = `${apiPath.BANK_COMPANY_ALL_LIST}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data.body;
      this.model.setBankCompanyData(resultData)
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  postBankAccountAuth(){
    const path = `${apiPath.BANK_ACCOUNT_AUTH_OWNER}`;
    const body = {
      "body": {
        "account_number": this.model.bank_account_number,
        "bank_company_uid": this.model.bank_company_uid,
        "firfin_user_uid": this.model.user.user_uid,
        "owner_name": this.model.user.name
        
      },
      "encrypt_body": "string",
      "header": {
        "notice": {
          "button": {
            "button_count": 0,
            "negative_button": {
              "action": "string",
              "text": "string"
            },
            "positive_button": {
              "action": "string",
              "text": "string"
            }
          },
          "is_cancelable": true,
          "is_visibility": true,
          "message": "작은내용",
          "title": "타이틀",
          "type": "ALERT"
        },
        "request_at": "2021-12-27 01:00:00",
        "response_at": "2021-12-27 01:00:02",
        "result": {
          "code": "200",
          "description": "age 값은 0보다 커야 합니다.",
          "message": "시스템 오류입니다.",
          "request_method": "GET",
          "request_uri": "https://lemontree.ai/user"
        }
      },
      "pagination": {
        "current_elements": 20,
        "current_page": 0,
        "page": 0,
        "size": 20,
        "total_elements": 2000,
        "total_page": 10
      },
      "sort": {
        "order_by": "id",
        "sort_by": "ASC"
      }
    }
    POST_REQUEST(path,body).then(
    (success) => {
      const resultData = success.data.body;
      this.model.is_bank_account_auth = true;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  
}
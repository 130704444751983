<template>
  <div class="group_radio">
    <RadioItem
      v-for="(item, index) in dataList"
      :key="item.codeId || index"
      :name="name"
      :id="item.codeId || item.id"
      :text="item.codeName || item.text || item.name"
      :item="item"
      :colorSelect="colorSelect"
      :isDisabled="isDisabled || item.isDisabled" 
      :value.sync="valueSync" />
  </div>
</template>

<script>
import RadioItem from './RadioItem'

export default {
  name: 'Radio',
  components: {
    RadioItem
  },
  props: {
    dataList: Array,
    name: String,
    value: undefined,
    colorSelect: String,
    isDisabled: {
      type: Boolean,
      default: false
    },
    placeholder:String,
  },
  computed: {
    valueSync: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      }
    },
  },
}
</script>

<style scoped>
.radio_comm{display:block;position:relative;padding:16px 20px}

.radio_comm ::v-deep input[type="radio"]{position:absolute;top:0;bottom:0;left:0;right:0;z-index:10;width:100%;height:100%;border:0 none;opacity:0.01;filter:alpha(opacity=1);cursor:pointer}
.radio_comm ::v-deep label{display:block;padding-left:36px;line-height:20px}
.radio_comm ::v-deep .txt_radio{font-weight:700;font-size:16px;line-height:24px;color:#666;letter-spacing:-0.02em;word-break:break-all}
.radio_comm ::v-deep .txt_radio .box_color{display:inline-block;width:18px;height:18px;margin-right:4px;border-radius:5px;vertical-align:top}
.radio_comm ::v-deep .txt_radio .icon_firfin{margin-right:4px;padding:3px;border-radius:4px}
/* .radio_comm ::v-deep .txt_radio .icon_firfin{margin-right:4px} */
.radio_comm ::v-deep .icon_radio{position:absolute;top:0;bottom:0;left:20px;width:24px;height:24px;z-index:1;margin:auto;border:1px solid #E7E5E5;border-radius:100%;font-size:0;line-height:18px;white-space:nowrap;box-sizing:border-box;text-align:center}
.radio_comm ::v-deep .icon_radio .icon_check{margin:2px}
.radio_comm ::v-deep .icon_radio:after{display:inline-block;height:100%;vertical-align:middle;content:''}
/* .radio_comm ::v-deep .icon_radio .mark_active{display:inline-block;width:8px;height:8px;border-radius:100%;vertical-align:middle;white-space:normal} */

/* hover */
/* .radio_comm ::v-deep input:hover + label .txt_radio{color:$PRIMARY_COLOR} */
/* .radio_comm ::v-deep input:checked:hover + label .txt_radio{color:#333} */

/* cheked */
.radio_comm ::v-deep input:checked + label + .icon_radio{border-color:#BEA9ED;background-color:#BEA9ED}

/* disabled */
/* .radio_comm ::v-deep input:disabled,
.radio_comm ::v-deep input:disabled:checked{cursor:no-drop}
.radio_comm ::v-deep input:disabled + label .txt_radio{color:$DISABLED_TEXT}
.radio_comm ::v-deep input:disabled + label + .icon_radio{border-color:$DISABLED_BORDER}
.radio_comm ::v-deep input:disabled:checked + label + .icon_radio .mark_active{background-color:$DISABLED_ICON} */

/* .radio_comm ::v-deep + .radio_comm{margin-left:32px} */
</style>
export default class LeaveModel {
  constructor(){
    this.family = {};
    this.unregister_type_list = [];
    this.user = {};
    this.wallet = {};

    this.bank_company_list = [];

    this.is_bank_account_auth = false;

    this.unregister_type = '';
    this.unregister_description = '';
    this.bank_company_uid = '';
    this.bank_account_number = '';
    this.is_agree_family_delete = false;
    this.pin_code_verify_uid = '';
    this.sms_auth_verify_uid = '';
  }
  setData(obj){
    const { family, unregister_type_list, user, wallet } = obj;
    this.family = family;
    this.unregister_type_list = unregister_type_list;
    this.user = user;
    this.wallet = wallet;
    if(user.role != 'PARENTS'){
      this.is_agree_family_delete = true;
    }
  }
  setBankCompanyData(obj){
    const { bank_company_list } = obj;
    this.bank_company_list = bank_company_list;
  }
  getLeaveData(){
    let obj = {
      unregister_type : this.unregister_type || 'ETC',
      unregister_description : this.unregister_description,
      bank_company_uid : this.bank_company_uid,
      bank_account_number : this.bank_account_number,
      is_agree_family_delete : this.is_agree_family_delete,
    }
    return obj;
  }
}